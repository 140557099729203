import { h, Component } from 'preact';
import Dialog from 'preact-material-components/Dialog';
import Button from 'preact-material-components/Button';
import List from 'preact-material-components/List';
import '../../../node_modules/preact-material-components/List/style.css';
import '../../../node_modules/preact-material-components/Button/style.css';
import '../../../node_modules/preact-material-components/Dialog/style.css';
import { route } from 'preact-router';


export default class ModalPergunta extends Component {

	goNaoPreencheRequisitos = () => route('/nao-preenche-requisitos');


	render() {
		if (this.props.isOpen) {
			this.modalDialog.MDComponent.show();
		}
		return (
			<div>
				<Dialog ref={modalDialog => { this.modalDialog = modalDialog; }} >
					<Dialog.Header> {this.props.pergunta.natureza}</Dialog.Header>
					<Dialog.Body>
						{this.props.pergunta.pergunta}
					</Dialog.Body>
					<Dialog.Footer>
						<Dialog.FooterButton className='mdc-button mdc-button--raised mdc-ripple-upgraded' style={{ marginLeft: '20px', position: 'absolute', left: '0' }} onclick={this.props.acceptAll} accept={this.props.modalEnded}>Não para todos</Dialog.FooterButton>
						<Dialog.FooterButton onclick={this.goNaoPreencheRequisitos} >Sim</Dialog.FooterButton>
						<Dialog.FooterButton className='mdc-button mdc-button--raised mdc-ripple-upgraded' onclick={this.props.increaseCounter} accept={this.props.modalEnded}>Não</Dialog.FooterButton>
					</Dialog.Footer>
				</Dialog>
			</div>
		)

	}
}