import { Component } from 'preact';
import Card from 'preact-material-components/Card';
import '../../../../node_modules/preact-material-components/Card/style.css';

import Button from 'preact-material-components/Button';
import '../../../../node_modules/preact-material-components/Button/style.css';
import GeraPdfModal from './geraPdfModal'
import { route } from 'preact-router';
import geraPdfArquivo from './geraPdfArquivo'

export default class geraPdfDados extends Component {

	state = {
		modalOpen: false,
		modalEnded: false,
		investigadoNome: '',
		investigadoCPF: '',
	};



	showModal = () => {
		this.setState({ modalOpen: true });

	};

	geraPdf = () => {
		const dadosPdf = { ...this.props.parentState, ...this.state }
		geraPdfArquivo(dadosPdf)
	}

	handleChangeInvestigadoNome = (event) => {
		this.setState({ investigadoNome: event.target.value });
	}

	cpfMask = value => {
		return value
			.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
			.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d{1,2})/, '$1-$2')
			.replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
	}

	handleChangeInvestigadoCPF = (event) => {
		this.setState({ investigadoCPF: this.cpfMask(event.target.value) });
	}

	render() {
		return (
			<div class='iniciar'>

				<Button class="salva-pdf" raised ripple style='margin: 0 auto;' onClick={this.showModal} > Gerar Relatório em PDF </Button>
				<GeraPdfModal
					isOpen={this.state.modalOpen}
					modalEnded={this.state.modalEnded}
					onDismiss={() => this.setState({ showPerguntas: false })}
					geraPdf={this.geraPdf}
					handleChangeInvestigadoNome={this.handleChangeInvestigadoNome}
					handleChangeInvestigadoCPF={this.handleChangeInvestigadoCPF}
					parentState={this.state}

				/>
			</div>
		);
	}
}
