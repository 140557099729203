/* eslint-disable no-var */
/* eslint-disable semi */
/* eslint-disable indent */

import { createPdf } from "pdfmake/build/pdfmake.min.js";
import ReactGA from 'react-ga';



const fonts = {
    // download default Roboto font from cdnjs.com
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    },
};

var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
const lowerCaseDate = new Date().toLocaleString('pt-BR', options);
const myDate = lowerCaseDate.charAt(0).toUpperCase() + lowerCaseDate.slice(1);

const geraPdfArquivo = (props) => {
    ReactGA.event({
        category: 'Relatório',
        action: 'Gerado relatório'
    });

    var dd = {
        content: [
            {
                text: 'SCPP/ANPP',
                style: 'header',
                bold: true,
                fontSize: 30

            },
            {
                text: 'Sistema de Cálculos de Prestações Pecuniárias \n para Acordos de Não Persecução Penal',
                style: 'header',
                bold: false,
                fontSize: 12,
            },
            '\n',
            { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
            '\n',
            { text: 'Relatório', style: 'header', fontSize: 20, bold: true, alignment: 'center' },
            '\n',
            {
                text: [{
                    text: 'Nome: ',
                    bold: true,
                },
                {
                    text: props.investigadoNome,

                },
                {
                    text: ' (CPF: '
                },
                {
                    text: props.investigadoCPF,

                },
                    ')'
                ],
                color: '#005674',
                fontSize: 12,
                lineHeight: 1.2,
            },
            '\n',
            '\n',
            'Fase 1',
            {
                style: 'tableExample',
                table: {
                    widths: [230, '*', 100],
                    body: [
                        ['Critérios', 'Tabela de Avaliação', 'Pontuação Final'],
                        [
                            {
                                stack: ['\n',
                                    {
                                        ul: [
                                            { text: [{ text: 'Motivação do Crime: ', bold: true }, { text: props.motivacaoCrimeString, bold: true, color: 'red' }] },
                                            { text: [{ text: 'Consequências do Crime: ', bold: true }, { text: props.consequenciaCrimeString, bold: true, color: 'red' }] },
                                            { text: [{ text: 'Itinerário do Crime: ', bold: true }, { text: props.itinerarioCrimeString, bold: true, color: 'red' }] },

                                        ]
                                    }
                                ]
                            },
                            [
                                { text: 'Critérios de Gravidade', bold: true, alignment: 'center', color: 'red' },
                                {
                                    bold: true,
                                    table: {
                                        body: [
                                            ['A', 'Abaixo de 21 pontos'],
                                            ['B', "de 21 a 40 pontos"],
                                            ['C', "de 41 a 60 pontos"],
                                            ['D', 'de 61 a 81 pontos'],
                                            ['E', 'acima de 81 pontos']
                                        ],

                                    },

                                },

                            ],
                            {
                                stack: ['\n',
                                    {
                                        ul: [
                                            { text: [{ text: 'Pontuação: ', bold: true }, { text: props.pontuacaoTotal, bold: true, color: 'red' }] },
                                            { text: [{ text: 'Gravidade: ', bold: true }, { text: props.pontuacaoGravidade, bold: true, color: 'red' }] },
                                        ]
                                    }
                                ]
                            },
                        ]
                    ]
                }
            },
            '\n',
            'Fase 2',
            {
                style: 'tableCriterios',
                table: {
                    widths: [230, '*', 100],
                    body: [
                        ['Critérios', 'Faixa Percentual Sugerida', 'Percentual Escolhido'],
                        [
                            {
                                text: [
                                    { text: 'Faixa de Renda: ', bold: true },
                                    { text: props.faixaRendaString, bold: true, color: 'red' },
                                    '\n',
                                    { text: 'Salário Mínimo de Referência: R$ ', bold: true },
                                    { text: props.salarioMinimo, bold: true, color: 'red' },
                                    '\n ',

                                ],
                                margin: [0, 10, 0, 0],

                            },
                            {
                                text: [{ text: 'Mínimo: ' }, { text: props.minPercentualTetoFloat, bold: true }, '%',
                                    '\n',
                                { text: 'Máximo: ' }, { text: props.maxPercentualTetoFloat, bold: true }, '%',
                                ],
                                margin: [30, 15, 0, 0],
                            },

                            { text: props.percentualTeto, bold: true, color: 'red', margin: [0, 15, 0, 0], fontSize: 25, alignment: 'center' }
                            ,
                        ]
                    ]
                }
            },
            '\n',
            'Cálculo',
            {
                style: 'tableCriterios',
                bold: true,
                table: {
                    widths: [300, '*'],
                    body: [
                        ['Critérios', 'Valor'],
                        [
                            'Teto ( 360 vezes o salário mínimo - Art. 45, §1º do CP):',
                            (props.teto + props.salarioMinimoFloat).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                        ],
                        [
                            'Cálculo Percentual sobre o Teto:',
                            (props.valorPercentualTeto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                        ],
                        [
                            'Salário Mínimo:', 'R$ ' + props.salarioMinimo
                        ],

                    ]
                }
            },
            '\n\n',
            { text: "Valor Final (Salário Mínimo + Cálculo Percentual do Teto):", fontSize: 15, bold: true, alignment: 'center' },
            '\n',
            { text: (props.valorFinal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), fontSize: 25, bold: true, alignment: 'center', color: 'red' },
            '\n\n\n',
            { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
            { text: myDate, fontSize: 10, alignment: 'right', margin: [0, 10, 0, 0] },


        ],
        styles: {
            header: {
                fontSize: 14,
                bold: true,
                alignment: 'center'
            },
            subheader: {
                fontSize: 15,
                bold: true
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            },
            tableCriterios: {
                margin: [0, 10, 0, 0]
            }
        }
    }


    createPdf(dd, null, fonts).download();

};

export default geraPdfArquivo;