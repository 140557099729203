import { h, Component } from 'preact';
import Button from 'preact-material-components/Button';
import '../../../node_modules/preact-material-components/Button/style.css';
import Card from 'preact-material-components/Card';
import '../../../node_modules/preact-material-components/Card/style.css';
import Select from 'preact-material-components/Select';
import '../../../node_modules/preact-material-components/List/style.css';
import 'preact-material-components/Menu/style.css';
import 'preact-material-components/Select/style.css';
import CurrencyInput from 'react-currency-input';
import style from './style';
import CardPrimeiraFase from './cardPrimeiraFase'
import CardSegundaFase from './cardSegundaFase'
import LayoutGrid from 'preact-material-components/LayoutGrid';
import 'preact-material-components/LayoutGrid/style.css';
import AnimateOnChange from 'preact-animate-on-change';
import GeraPdfDados from './geraPdf/geraPdfDados'


const percentuaisRendaGravidade = {
	rendaNivel0: {
		'A': { min: 0, max: 0 },
		'B': { min: 0.1, max: 1 },
		'C': { min: 1, max: 5.1 },
		'D': { min: 5, max: 11 },
		'E': { min: 12, max: 21 }
	},
	rendaNivel1: {
		'A': { min: 0, max: 0 },
		'B': { min: 1, max: 5 },
		'C': { min: 5.1, max: 10 },
		'D': { min: 11, max: 20 },
		'E': { min: 21, max: 40 },
	},
	rendaNivel2: {
		'A': { min: 0.1, max: 10 },
		'B': { min: 4, max: 15 },
		'C': { min: 16, max: 30 },
		'D': { min: 31, max: 40 },
		'E': { min: 41, max: 50 },
	},
	rendaNivel3: {
		'A': { min: 0.2, max: 12 },
		'B': { min: 7, max: 20 },
		'C': { min: 21, max: 35 },
		'D': { min: 36, max: 50 },
		'E': { min: 51, max: 65 },
	},
	rendaNivel4: {
		'A': { min: 0.3, max: 20 },
		'B': { min: 10, max: 30 },
		'C': { min: 31, max: 50 },
		'D': { min: 51, max: 76 },
		'E': { min: 76, max: 100 },
	}
}

const salarioMinimoInicial = 1045.00
export default class Calculadora extends Component {
	state = {
		indexMotivacaoCrime: 0,
		indexConsequenciaCrime: 0,
		indexItinerarioCrime: 0,
		indexFaixaRenda: 1,
		motivacaoCrime: 0,
		motivacaoCrimeString: '',
		motivacaoCrimeTemp: 0,
		consequenciaCrime: 0,
		consequenciaCrimeString: '',
		itinerarioCrime: 0,
		itinerarioCrimeString: '',
		pontuacaoTotal: 0,
		pontuacaoTotaldiff: 0,
		pontuacaoGravidade: 'A',
		salarioMinimo: "1.045,00",
		salarioMinimoFloat: salarioMinimoInicial,
		teto: 0,
		tetoDiff: 0,
		percentualTeto: 0,
		percentualTetoFloat: 0,
		minPercentualTetoFloat: 0,
		minPercentualTetoFloatDiff: 0,
		maxPercentualTetoFloat: 0,
		maxPercentualTetoFloatDiff: 0,
		faixaRenda: 'rendaNivel0',
		faixaRendaString: 'PF baixa renda (até 2 salários-mínimos)',
		valorPercentualTeto: 0,
		valorPercentualTetoDiff: 0,
		valorFinal: salarioMinimoInicial,
		valorFinalDiff: 0,

	};

	componentDidMount() {
		this.setState({
			teto: this.state.salarioMinimoFloat * 359,
			valorFinal: this.state.salarioMinimoFloat
		})
	}


	atualizaDiff = (valor) => {
		// console.log('[VALOR', valor)
		// console.log('valorDIFF', valor.teto, this.state.teto)

		if (valor.teto) {
			if (valor.teto !== this.state.teto) {
				this.setState({ tetoDiff: 1 })
			} else {
				this.setState({ tetoDiff: 0 })
			}
		} else {
			this.setState({ tetoDiff: 0 })
		}

		if (valor.valorPercentualTeto) {
			if (valor.valorPercentualTeto !== this.state.valorPercentualTeto) {
				this.setState({
					valorPercentualTetoDiff: 1,
				})
			} else {
				this.setState({
					valorPercentualTetoDiff: 0,
				})
			}
		} else {
			this.setState({
				valorPercentualTetoDiff: 0,
			})
		}

		if (valor.valorFinal) {
			if (valor.valorFinal !== this.state.valorFinal) {
				this.setState({
					valorFinalDiff: 1
				})
			} else {
				this.setState({
					valorFinalDiff: 0
				})
			}
		} else {
			this.setState({
				valorFinalDiff: 0
			})
		}
	}

	atualizaState = (valor) => {
		this.atualizaDiff(valor)
		this.setState(valor)
		this.forceUpdate()

	}

	atualizaParentFaixaPercentualTeto = (event) => {
		let faixaRenda, faixaRendaString, indexFaixaRenda
		try {
			faixaRenda = event.target.value
			indexFaixaRenda = event.target.selectedIndex
			faixaRendaString = event.target[indexFaixaRenda].text
		} catch (err) {
			faixaRenda = this.state.faixaRenda
			indexFaixaRenda = this.state.indexFaixaRenda
			faixaRendaString = this.state.faixaRendaString
		}

		let percentuaisPossiveisParaRenda = percentuaisRendaGravidade[faixaRenda]
		let percentualRendaGravidade = percentuaisPossiveisParaRenda[this.state.pontuacaoGravidade]
		console.log('percentualchild', this.state.pontuacaoGravidade, percentualRendaGravidade)

		let minPercentualTetoFloatDiff
		if (percentualRendaGravidade.min != this.state.minPercentualTetoFloat) {
			minPercentualTetoFloatDiff = 1
		} else {
			minPercentualTetoFloatDiff = 0
		}
		let maxPercentualTetoFloatDiff
		if (percentualRendaGravidade.min != this.state.maxPercentualTetoFloat) {
			maxPercentualTetoFloatDiff = 1
		} else {
			maxPercentualTetoFloatDiff = 0
		}

		this.setState({
			faixaRenda: faixaRenda,
			faixaRendaString: faixaRendaString,
			indexFaixaRenda: indexFaixaRenda,
			minPercentualTetoFloat: percentualRendaGravidade.min,
			minPercentualTetoFloatDiff: minPercentualTetoFloatDiff,
			maxPercentualTetoFloat: percentualRendaGravidade.max,
			maxPercentualTetoFloatDiff: maxPercentualTetoFloatDiff
		})
	}

	render() {
		return (
			<div class='calculadora'>
				<h1 class="titulo-calculadora" >Simulação de Valores</h1>

				<LayoutGrid >
					<LayoutGrid.Inner>
						<LayoutGrid.Cell cols="6">
							{/* Fase 1 */}
							<div class='card-fase1' >
								<CardPrimeiraFase
									parentState={this.state}
									atualizaState={this.atualizaState}
									atualizaParentFaixaPercentualTeto={this.atualizaParentFaixaPercentualTeto}
								/>
							</div>

						</LayoutGrid.Cell>

						<LayoutGrid.Cell cols="6" >

							{/* Fase 2 */}
							<div class='card-fase2'>
								<CardSegundaFase
									parentState={this.state}
									atualizaState={this.atualizaState}
									calculaGravidadeConduta={this.calculaGravidadeConduta}
									atualizaParentFaixaPercentualTeto={this.atualizaParentFaixaPercentualTeto}
									atualizaDiff={this.atualizaDiff}
								/>


							</div>
						</LayoutGrid.Cell>
					</LayoutGrid.Inner>
				</LayoutGrid>
				{/* Composição de Cálculo */}
				<div>
					<Card class='card-fase3'>
						<h1 class='card-fase3-titulo'>Composição do Cálculo</h1>
						<table class='composicao-do-calculo'>
							<tr>
								<td class='composicao-bold'>Teto ( 360 vezes o salário mínimo - Art. 45, §1º do CP):</td>
								<td>
									<AnimateOnChange
										baseClassName="Teto"
										animationClassName="highlightText"
										animate={this.state.tetoDiff != 0}
									>
										{(this.state.teto * (360 / 359)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
									</AnimateOnChange>
								</td>
							</tr>
							<tr>
								<td class='composicao-bold'>Cálculo Percentual sobre o Teto: </td>
								<td>
									<AnimateOnChange
										baseClassName="pontuacaoTotal"
										animationClassName="highlightText"
										animate={this.state.valorPercentualTetoDiff != 0}
									>
										{this.state.valorPercentualTeto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
									</AnimateOnChange>
								</td>
							</tr>
							<tr>
								<td class='composicao-bold'> Valor Final (Salário Mínimo + Cálculo Percentual do Teto): </td>
								<td>
									<AnimateOnChange
										baseClassName="valor-final"
										animationClassName="highlightText"
										animate={this.state.valorFinalDiff != 0}
									>
										{this.state.valorFinal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
									</AnimateOnChange>
								</td>
							</tr>
						</table>

						{/* Botão que gera PDF */}

					</Card>
					<GeraPdfDados
						parentState={this.state} />
				</div>
				<br />
			</div >

		);
	}
}
