// gerado no libreoffice e colado aqui
const BaseLegal = () => {
    return (
        <div>
            <p class="western" style="page-break-before: always">
                Art. 28-A. N&atilde;o sendo caso de arquivamento e tendo o
                investigado confessado formal e circunstancialmente a pr&aacute;tica
                de infra&ccedil;&atilde;o penal sem viol&ecirc;ncia ou grave amea&ccedil;a
                e com pena m&iacute;nima inferior a 4 (quatro) anos, o Minist&eacute;rio
                P&uacute;blico poder&aacute; propor acordo de n&atilde;o persecu&ccedil;&atilde;o
                penal, desde que necess&aacute;rio e suficiente para reprova&ccedil;&atilde;o
                e preven&ccedil;&atilde;o do crime, mediante as seguintes condi&ccedil;&otilde;es
                ajustadas cumulativa e alternativamente:
</p>
            <p class="western">I - reparar o dano ou restituir a coisa &agrave;
            v&iacute;tima, exceto na impossibilidade de faz&ecirc;-lo;
</p>
            <p class="western">II - renunciar voluntariamente a bens e direitos
            indicados pelo Minist&eacute;rio P&uacute;blico como instrumentos,
            produto ou proveito do crime;
</p>
            <p class="western">III - prestar servi&ccedil;o &agrave; comunidade
            ou a entidades p&uacute;blicas por per&iacute;odo correspondente &agrave;
            pena m&iacute;nima cominada ao delito diminu&iacute;da de um a dois
            ter&ccedil;os, em local a ser indicado pelo ju&iacute;zo da execu&ccedil;&atilde;o,
            na forma do art. 46 do Decreto-Lei n&ordm; 2.848, de 7 de dezembro de
            1940 (C&oacute;digo Penal);
</p>
            <p class="western">IV - pagar presta&ccedil;&atilde;o pecuni&aacute;ria,
            a ser estipulada nos termos do art. 45 do Decreto-Lei n&ordm; 2.848,
            de 7 de dezembro de 1940 (C&oacute;digo Penal), a entidade p&uacute;blica
            ou de interesse social, a ser indicada pelo ju&iacute;zo da execu&ccedil;&atilde;o,
            que tenha, preferencialmente, como fun&ccedil;&atilde;o proteger bens
            jur&iacute;dicos iguais ou semelhantes aos aparentemente lesados pelo
            delito; ou
</p>
            <p class="western">V - cumprir, por prazo determinado, outra condi&ccedil;&atilde;o
            indicada pelo Minist&eacute;rio P&uacute;blico, desde que
            proporcional e compat&iacute;vel com a infra&ccedil;&atilde;o penal
            imputada.
</p>
            <p class="western">&sect; 1&ordm; Para aferi&ccedil;&atilde;o da pena
            m&iacute;nima cominada ao delito a que se refere o caput deste
            artigo, ser&atilde;o consideradas as causas de aumento e diminui&ccedil;&atilde;o
            aplic&aacute;veis ao caso concreto.
</p>
            <p class="western">&sect; 2&ordm; O disposto no caput deste artigo
            n&atilde;o se aplica nas seguintes hip&oacute;teses:
</p>
            <p class="western">I - se for cab&iacute;vel transa&ccedil;&atilde;o
            penal de compet&ecirc;ncia dos Juizados Especiais Criminais, nos
            termos da lei;
</p>
            <p class="western">II - se o investigado for reincidente ou se houver
            elementos probat&oacute;rios que indiquem conduta criminal habitual,
            reiterada ou profissional, exceto se insignificantes as infra&ccedil;&otilde;es
penais pret&eacute;ritas;</p>
            <p class="western">III - ter sido o agente beneficiado nos 5 (cinco)
            anos anteriores ao cometimento da infra&ccedil;&atilde;o, em acordo
            de n&atilde;o persecu&ccedil;&atilde;o penal, transa&ccedil;&atilde;o
penal ou suspens&atilde;o condicional do processo; e</p>
            <p class="western">IV - nos crimes praticados no &acirc;mbito de
            viol&ecirc;ncia dom&eacute;stica ou familiar, ou praticados contra a
            mulher por raz&otilde;es da condi&ccedil;&atilde;o de sexo feminino,
em favor do agressor.</p>
            <p class="western">&sect; 3&ordm; O acordo de n&atilde;o persecu&ccedil;&atilde;o
            penal ser&aacute; formalizado por escrito e ser&aacute; firmado pelo
            membro do Minist&eacute;rio P&uacute;blico, pelo investigado e por
            seu defensor.
</p>
            <p class="western">&sect; 4&ordm; Para a homologa&ccedil;&atilde;o do
            acordo de n&atilde;o persecu&ccedil;&atilde;o penal, ser&aacute;
            realizada audi&ecirc;ncia na qual o juiz dever&aacute; verificar a
            sua voluntariedade, por meio da oitiva do investigado na presen&ccedil;a
            do seu defensor, e sua legalidade.
</p>
            <p class="western">&sect; 5&ordm; Se o juiz considerar inadequadas,
            insuficientes ou abusivas as condi&ccedil;&otilde;es dispostas no
            acordo de n&atilde;o persecu&ccedil;&atilde;o penal, devolver&aacute;
            os autos ao Minist&eacute;rio P&uacute;blico para que seja
            reformulada a proposta de acordo, com concord&acirc;ncia do
investigado e seu defensor.</p>
            <p class="western">&sect; 6&ordm; Homologado judicialmente o acordo
            de n&atilde;o persecu&ccedil;&atilde;o penal, o juiz devolver&aacute;
            os autos ao Minist&eacute;rio P&uacute;blico para que inicie sua
            execu&ccedil;&atilde;o perante o ju&iacute;zo de execu&ccedil;&atilde;o
penal.</p>
            <p class="western">&sect; 7&ordm; O juiz poder&aacute; recusar
            homologa&ccedil;&atilde;o &agrave; proposta que n&atilde;o atender
            aos requisitos legais ou quando n&atilde;o for realizada a adequa&ccedil;&atilde;o
            a que se refere o &sect; 5&ordm; deste artigo.
</p>
            <p class="western">&sect; 8&ordm; Recusada a homologa&ccedil;&atilde;o,
            o juiz devolver&aacute; os autos ao Minist&eacute;rio P&uacute;blico
            para a an&aacute;lise da necessidade de complementa&ccedil;&atilde;o
            das investiga&ccedil;&otilde;es ou o oferecimento da den&uacute;ncia.
</p>
            <p class="western">&sect; 9&ordm; A v&iacute;tima ser&aacute;
            intimada da homologa&ccedil;&atilde;o do acordo de n&atilde;o
            persecu&ccedil;&atilde;o penal e de seu descumprimento.
</p>
            <p class="western">&sect; 10. Descumpridas quaisquer das condi&ccedil;&otilde;es
            estipuladas no acordo de n&atilde;o persecu&ccedil;&atilde;o penal, o
            Minist&eacute;rio P&uacute;blico dever&aacute; comunicar ao ju&iacute;zo,
            para fins de sua rescis&atilde;o e posterior oferecimento de
den&uacute;ncia.</p>
            <p class="western">&sect; 11. O descumprimento do acordo de n&atilde;o
            persecu&ccedil;&atilde;o penal pelo investigado tamb&eacute;m poder&aacute;
            ser utilizado pelo Minist&eacute;rio P&uacute;blico como
            justificativa para o eventual n&atilde;o oferecimento de suspens&atilde;o
condicional do processo.</p>
            <p class="western">&sect; 12. A celebra&ccedil;&atilde;o e o
            cumprimento do acordo de n&atilde;o persecu&ccedil;&atilde;o penal
            n&atilde;o constar&atilde;o de certid&atilde;o de antecedentes
            criminais, exceto para os fins previstos no inciso III do &sect; 2&ordm;
deste artigo.</p>
            <p class="western">&sect; 13. Cumprido integralmente o acordo de n&atilde;o
            persecu&ccedil;&atilde;o penal, o ju&iacute;zo competente decretar&aacute;
a extin&ccedil;&atilde;o de punibilidade.</p>
            <p class="western">&sect; 14. No caso de recusa, por parte do
            Minist&eacute;rio P&uacute;blico, em propor o acordo de n&atilde;o
            persecu&ccedil;&atilde;o penal, o investigado poder&aacute; requerer
            a remessa dos autos a &oacute;rg&atilde;o superior, na forma do art.
28 deste C&oacute;digo.</p>
        </div>
    )
}
export default BaseLegal