import { h, Component } from 'preact';
import Dialog from 'preact-material-components/Dialog';
import Button from 'preact-material-components/Button';
import List from 'preact-material-components/List';
import '../../../../node_modules/preact-material-components/List/style.css';
import '../../../../node_modules/preact-material-components/Button/style.css';
import '../../../../node_modules/preact-material-components/Dialog/style.css';
import { route } from 'preact-router';
import TextField from 'preact-material-components/TextField';
import 'preact-material-components/TextField/style.css';


export default class GeraPdfModal extends Component {



	render() {
		if (this.props.isOpen) {
			this.modalDialog.MDComponent.show();
		}

		return (
			<div >
				<Dialog ref={modalDialog => { this.modalDialog = modalDialog; }} className='gera-pdf-modal'>
					<Dialog.Header> Insira Dados do Investigado (opcional) </Dialog.Header>
					<span style={{ fontSize: '80%', fontStyle: 'italic', color: 'grey' }} > Os dados informados não são coletados e permanecem apenas no seu navegador. </span>
					<Dialog.Body>
						<div>
							<div class="mdc-typography--caption"> Nome do Investigado: </div>
							<input
								class='mdc-select__native-control salario-minimo-referencia'
								value={this.props.parentState.investigadoNome}
								onChange={this.props.handleChangeInvestigadoNome}
								type='text'
							/>

						</div>
						<div>
							<div class="mdc-typography--caption"> CPF do Investigado: </div>
							<input
								class='mdc-select__native-control salario-minimo-referencia'
								value={this.props.parentState.investigadoCPF}
								onInput={this.props.handleChangeInvestigadoCPF}
								type='text'
								pattern="[0-9]{11}"

							/>
						</div>
					</Dialog.Body>
					<Dialog.Footer>
						<Dialog.FooterButton className='mdc-button mdc-button--raised mdc-ripple-upgraded' onclick={this.props.geraPdf} accept={this.props.modalEnded}>Gerar PDF</Dialog.FooterButton>
						<Dialog.FooterButton cancel={true} >Cancelar</Dialog.FooterButton>
					</Dialog.Footer>
				</Dialog>
			</div >
		)

	}
}