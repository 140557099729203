import { h, Component } from 'preact';
import Button from 'preact-material-components/Button';
import '../../../node_modules/preact-material-components/Button/style.css';
import Card from 'preact-material-components/Card';
import '../../..//node_modules/preact-material-components/Card/style.css';
import LayoutGrid from 'preact-material-components/LayoutGrid';
import '../../../node_modules/preact-material-components/LayoutGrid/style.css';
import style from './style';

export default class Desenvolvedores extends Component {

	render() {
		return (
			<div class='desenvolvedores'>
				<LayoutGrid >
					<LayoutGrid.Inner>
						<LayoutGrid.Cell cols="6">
							{/* Fase 1 */}
							<Card className='desenvolvedor-card'>
								<div class={style.cardHeader}>
									<h2 class="mdc-typography--title">Aldo de Campos Costa</h2>
								</div>
								<div class='desenvolvedor-texto'>
									<div class={style.cardBody}>
										Procurador da República (MPF)
									</div>
								</div>
								<a href="mailto:aldocampos@mpf.mp.br" class='desenvolvedor-email'>
									aldocampos@mpf.mp.br
									</a>

							</Card>

						</LayoutGrid.Cell>

						<LayoutGrid.Cell cols="6" >

							{/* Fase 2 */}
							<div class='desenvolvedor-card'>
								<Card>
									<div class={style.cardHeader}>
										<h2 class="mdc-typography--title">Alberto Vinícius Cartaxo da Cunha</h2>
									</div>
									<div class='desenvolvedor-texto'>

										<div class={style.cardBody}>
											Promotor de Justiça (MPPB)
									</div>
									</div>
									<a href="mailto:alberto.cartaxo@mppb.mp.br" class='desenvolvedor-email'>
										alberto.cartaxo@mppb.mp.br
									</a>

								</Card>
							</div>
						</LayoutGrid.Cell>
					</LayoutGrid.Inner>
				</LayoutGrid>
			</div >

		);
	}
}
