import { Component } from 'preact';
import Card from 'preact-material-components/Card';
import '../../../node_modules/preact-material-components/Card/style.css';

import Button from 'preact-material-components/Button';
import '../../../node_modules/preact-material-components/Button/style.css';
import ModalPergunta from './ModalPergunta'
import { route } from 'preact-router';
import perguntas from './perguntas'

export default class PreenchimentoRequisitos extends Component {

	constructor() {
		super();
		this.state = {
			showPerguntas: false,
			modalEnded: false,
			perguntaCounter: 0,
			pergunta: perguntas[0]
		};
	}


	increaseCounter = () => {
		if (this.state.perguntaCounter < perguntas.length - 1) {
			this.setState(
				{
					perguntaCounter: this.state.perguntaCounter + 1,
					pergunta: perguntas[this.state.perguntaCounter + 1]
				}
			)
		} else {
			this.setState({ modalEnded: true })
			route('/calculadora');
		}
	}
	showModal = () => {
		this.setState({ showPerguntas: true });

	};

	acceptAll = () => {
		route('/calculadora');
	}

	render() {
		return (
			<div class='iniciar'>
				<Button raised ripple class="iniciar-button" onClick={this.showModal} >Iniciar</Button>
				<ModalPergunta
					isOpen={this.state.showPerguntas}
					modalEnded={this.state.modalEnded}
					pergunta={this.state.pergunta}
					increaseCounter={this.increaseCounter}
					acceptAll={this.acceptAll}
					onDismiss={() => this.setState({ showPerguntas: false })}

				/>
			</div>
		);
	}
}
