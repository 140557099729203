import { h, Component } from 'preact';
import { route } from 'preact-router';
import TopAppBar from 'preact-material-components/TopAppBar';
import Drawer from 'preact-material-components/Drawer';
import List from 'preact-material-components/List';
import 'preact-material-components/Drawer/style.css';
import '../../../node_modules/preact-material-components/List/style.css';
import 'preact-material-components/TopAppBar/style.css';
// import style from './style';

export default class Header extends Component {
	closeDrawer() {
		this.drawer.MDComponent.open = false;
		this.state = {
			darkThemeEnabled: false
		};
	}

	openDrawer = () => (this.drawer.MDComponent.open = true);

	openSettings = () => this.dialog.MDComponent.show();

	drawerRef = drawer => (this.drawer = drawer);
	dialogRef = dialog => (this.dialog = dialog);

	linkTo = path => () => {
		route(path);
		this.closeDrawer();
	};

	render(props) {
		return (
			<div>
				<TopAppBar class="topappbar">

					<TopAppBar.Row>
						<TopAppBar.Section align-start>
							<TopAppBar.Icon menu onClick={this.openDrawer}>
								menu
							</TopAppBar.Icon>
							<TopAppBar.Title>Sistema de Cálculo de Prestações Pecuniárias para Acordos de Não Persecução Penal</TopAppBar.Title>
						</TopAppBar.Section>
					</TopAppBar.Row>

				</TopAppBar>
				<Drawer modal ref={this.drawerRef}>
					<Drawer.DrawerContent>
						<Drawer.DrawerItem selected={props.selectedRoute === '/'} onClick={this.linkTo('/')}>
							<List.ItemGraphic>home</List.ItemGraphic>
							Página Inicial
						</Drawer.DrawerItem>
						<Drawer.DrawerItem selected={props.selectedRoute === '/Calculadora'} onClick={this.linkTo('/calculadora')}>
							<List.ItemGraphic>border_color</List.ItemGraphic>
							Calculadora
						</Drawer.DrawerItem>
						<Drawer.DrawerItem selected={props.selectedRoute === '/manuais-de-atuacao'} onClick={this.linkTo('/manuais-de-atuacao')}>
							<List.ItemGraphic>library_books</List.ItemGraphic>
							Manuais de Atuação e Orientação Funcional
						</Drawer.DrawerItem>
						<Drawer.DrawerItem selected={props.selectedRoute === '/desenvolvedores'} onClick={this.linkTo('/desenvolvedores')}>
							<List.ItemGraphic>people</List.ItemGraphic>
							Desenvolvedores
						</Drawer.DrawerItem>
					</Drawer.DrawerContent >
				</Drawer >

			</div >
		);
	}
}
