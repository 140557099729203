import { h, Component } from 'preact';
import { Router } from 'preact-router';
import Header from './header';
import Home from '../routes/home';
import Calculadora from '../routes/calculadora';
import NotFound from '../routes/404';
import NaoPreencheRequisitos from '../routes/naoPreencheRequisitos'
import Desenvolvedores from '../routes/desenvolvedores'
import ManuaisDeAtuacao from '../routes/manuaisDeAtuacao'
import ReactGA from 'react-ga'

export default class App extends Component {
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.setState({
			currentUrl: e.url
		});

	};

	render() {
		ReactGA.initialize('UA-193815418-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
		return (
			<div id="app">
				<Header selectedRoute={this.state.currentUrl} />
				<Router onChange={this.handleRoute}>
					<Home path="/" />
					<NotFound default />
					<Calculadora path="/calculadora" />
					<NaoPreencheRequisitos path='/nao-preenche-requisitos' />
					<ManuaisDeAtuacao path='/manuais-de-atuacao' />
					<Desenvolvedores path='/desenvolvedores' />
				</Router>
			</div >
		);
	}
}
