const perguntas = [{
	natureza: 'Admissibilidade',
	pergunta: 'É caso de arquivamento?'
}, {
	natureza: 'Admissibilidade',
	pergunta: 'A pena da infração penal, ou a soma da pena do concurso de crimes, é superior a quatro anos, consideradas as causas de aumento ou diminuição de pena?'
}, {
	natureza: 'Admissibilidade',
	pergunta: 'A infração penal foi cometida com  violência?'
}, {
	natureza: 'Admissibilidade',
	pergunta: 'O investigado alegou inocência? (não confessou formalmente) '
}, {
	natureza: 'Admissibilidade',
	pergunta: 'O investigado confessou apenas formalmente o crime? (não confessou circunstanciadamente o delito)'
}, {
	natureza: 'Admissibilidade',
	pergunta: 'A infração penal foi cometida com grave ameaça?'
}, {
	natureza: 'Admissibilidade',
	pergunta: 'O acordo é insuficiente para reprovação e prevenção do crime?'
},
{
	natureza: 'Aplicabilidade',
	pergunta: 'É cabível transação penal de competência dos Juizados Especiais Criminais?'
}, {
	natureza: 'Aplicabilidade',
	pergunta: 'O investigado é reincidente?'
}, {
	natureza: 'Aplicabilidade',
	pergunta: 'As infraçãos penais pretéritas são significantes?'
}, {
	natureza: 'Aplicabilidade',
	pergunta: 'Há elementos probatórios que indiquem conduta criminal habitual?'
}, {
	natureza: 'Aplicabilidade',
	pergunta: 'Há elementos probatórios que indiquem conduta criminal reiterada?'
}, {
	natureza: 'Aplicabilidade',
	pergunta: 'Há elementos probatórios que indiquem conduta criminal profissional?'
}, {
	natureza: 'Aplicabilidade',
	pergunta: 'O agente foi beneficiado por acordo de não persecução penal nos cinco anos anteriores ao cometimento da infração?'
}, {
	natureza: 'Aplicabilidade',
	pergunta: 'O agente foi beneficiado por transação penal nos cinco anos anteriores ao cometimento da infração?'
}, {
	natureza: 'Aplicabilidade',
	pergunta: 'O agente foi beneficiado por suspensão condicional do processo nos cinco anos anteriores ao cometimento da infração?'
}, {
	natureza: 'Aplicabilidade',
	pergunta: 'O crime foi praticado no âmbito de violência doméstica ou familiar?'
}
]

export default perguntas