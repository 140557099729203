import { h, Component } from 'preact';
import Button from 'preact-material-components/Button';
import '../../../node_modules/preact-material-components/Button/style.css';
import Card from 'preact-material-components/Card';
import 'preact-material-components/Card/style.css';
import style from './style';
import { route } from 'preact-router';

export default class NaoPreencheRequisitos extends Component {



	render() {
		return (
			<div class={`${style.home} page`}>
				<h1></h1>
				<Card class='nao-preenche-requisitos' onClick={() => route('/')}>
					<div class={style.cardHeader}>
						<h2 class="mdc-typography--title">Os requisitos para celebrar ANPP não foram preenchidos.</h2>
					</div>
					<div class={style.cardBody}>
						Para realização de acordo de não persecução penal, é necessário preencher os pressupostos previstos no art. 28-A do CPP. <a style={{ color: 'white' }} href='./'> Clique aqui para reiniciar. </a>
					</div>

				</Card>

			</div >
		);
	}
}
