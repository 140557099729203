import { h, Component } from 'preact';
import Button from 'preact-material-components/Button';
import 'preact-material-components/Button/style.css';
import Card from 'preact-material-components/Card';
import '../../..//node_modules/preact-material-components/Card/style.css';
import 'preact-material-components/Card/style.css';
import ReactGA from 'react-ga';
import style from './style';

//  Procedimento: Copia PDF para a pasta. Adiciona item  na lista abaixa 
//  e constroi_build.py vai gerar a imagem.

const cardList = [

	{
		pdfPath: '../../../assets/documents/MPGO - 18_08_30_417_Manual_Acordo_de_Não_Persecução_Penal.pdf',
		imageSize: 150,
		fileText: "Manual de Atuação e Orientação Funcional - Acordo de Não Persecução Penal(ANPP) - MPGO",
		mpName: "",
		mpLogo: "assets/mp-logo/mpgo.png",
		mpReference: "CAO Criminal",
		mpHyperlink: "http://www.mpgo.mp.br/portal/pagina/criminal"
	},
	{
		pdfPath: '../../../assets/documents/MPPB - MANUAL DE NÃO PERSECUÇÃO PENAL.pdf',
		imageSize: 150,
		fileText: "Manual elementar para a formalização de acordos de não persecução - MPPB",
		mpName: "",
		mpLogo: "assets/mp-logo/mppb.png",
		mpReference: "CAO Criminal e de Execução Penal",
		mpHyperlink: "http://www.mppb.mp.br/index.php/area-criminal/19290"
	}, {
		pdfPath: '../../../assets/documents/MPPR - Protocolo_de_ANPP_-_versao_final_-_06-07-2020.pdf',
		imageSize: 150,
		fileText: "Protocolo de Atuação - Acordo de Não Persecução Penal - MPPR",
		mpName: "",
		mpLogo: "assets/mp-logo/mppr.png",
		mpReference: "CAOP Criminal, do Júri e Execuções Penais",
		mpHyperlink: "http://criminal.mppr.mp.br/modules/conteudo/conteudo.php?conteudo=2129"
	},

	{
		pdfPath: '../../../assets/documents/MPRN - Manual_ANPP_CAOP Criminal.pdf',
		imageSize: 150,
		fileText: "Manual de Atuação e Orientação Funcional - Acordo de Não Persecução Penal(ANPP) - MPRN",
		mpName: "",
		mpLogo: "assets/mp-logo/mprn.png",
		mpReference: "CAOP Criminal",
		mpHyperlink: "http://www.mprn.mp.br/portal/inicio/criminal/criminal-institucional/centros-de-apoio"
	},
	{
		pdfPath: '../../../assets/documents/MPSC - manual. anpp. lei anticrime.pdf',
		imageSize: 150,
		fileText: "Manual de Orientação: O Acordo de Não Pesecução Penal na Lei Anticrime (Lei 13.964/19)",
		mpName: "",
		mpLogo: "assets/mp-logo/mpsc.png",
		mpReference: "Comissão Especial",
		mpHyperlink: "https://www.mpsc.mp.br/centros-de-apoio-operacional/criminal"
	},
	{
		pdfPath: '../../../assets/documents/MPTO - MANUAL ANPP.pdf',
		imageSize: 150,
		fileText: "Manual de Acordo de Não Persecução Penal - MPTO",
		mpName: "",
		mpLogo: "assets/mp-logo/mpto.png",
		mpReference: "CAOP do Patrimônio Público e Criminal",
		mpHyperlink: "https://mpto.mp.br/caop-patrimonio-publico-e-criminal/"
	},

	{
		pdfPath: '../../../assets/documents/TJSP - AcordoDeNaoPersecucaoPenal.pdf',
		imageSize: 150,
		fileText: "Acordo de Não Persecução Penal: Como fazer na prática",
		mpName: "",
		mpLogo: "assets/mp-logo/tjsp.png",
		mpReference: "SGP 6 – Diretoria de Capacitação, Desenvolvimento de Talentos e Novos Projetos - TJSP",
		mpHyperlink: "https://www.tjsp.jus.br/CapacitacaoSistemas"
	},

]



const ImageComp = ({ src, index }) => (
	<div style={{ margin: '1rem', display: 'inline-block' }}>
		<img src={src} alt={index} />
		<p>Page Index: {index}</p>
	</div>
);

export default class ManuaisDeAtuacao extends Component {

	constructor(props) {
		super(props);
		this.geraCardDocumento = this.geraCardDocumento.bind(this);
		this.geraCardList = this.geraCardList.bind(this)
		this.state = {
			documents: [],
			filesLoading: true
		}
	}
	openInNewTab = (url, text) => {
		ReactGA.event({
			category: 'Download',
			action: 'Manuais Funcionais',
			label: text,
		});
		window.open(url, '_blank').focus();
	}

	geraCardDocumento(data) {

		let document = data.pdfPath.replaceAll('../', '');
		document = document.replaceAll(' ', '%20');
		let imagePath = 'assets/images' + document.slice('assets'.length, document.length - 4) + '.jpg'
		const component = (
			<div >
				< Card >
					<div class="card-header-manual">
						{data.mpLogo.length > 0 &&
							<img src={data.mpLogo} height="80" />
						}
						{data.mpName.length > 0 &&
							<h2 class="mdc-typography--title">
								{data.mpName}
							</h2>
						}
						{data.mpReference.length > 0 &&
							<div class="mdc-typography--caption card-caption-reduzido">{data.mpReference}</div>
						}
					</div>
					<a href="javascript:void(0)" onClick={() => this.openInNewTab(document, data.fileText).focus()} >
						<Card.Media className="card-media">
							<img class="manuais-funcionais-imagem" src={imagePath} />

						</Card.Media>
						<Card.MediaContent className='card-media-content'>
							{data.fileText}
						</Card.MediaContent>
					</a>

					<Card.Actions style={{ position: 'relative' }}>
						<Card.ActionButtons >
							<Card.ActionButton className='manuais-funcionais-button' onClick={() => this.openInNewTab(data.mpHyperlink).focus()} >Link institucional</Card.ActionButton>
						</Card.ActionButtons>
					</Card.Actions>

				</Card >




			</div >)
		return component


	}

	geraCardList(dataList) {


		const resultList = dataList.map((data, index) => (
			this.geraCardDocumento(data)
		))
		this.setState({
			documents: resultList,
			filesLoading: false
		})

		return resultList
	}


	componentDidMount() {
		this.geraCardList(cardList);

	}

	render() {
		return (

			<div class='manuais-de-atuacao-titulo'>

				<h1> Manuais de Atuação e Orientação Funcional</h1>
				{this.state.filesLoading &&
					<div>
						<div class="spinner windcatcher" id="windcatcher">
							<div class="blade"></div>
							<div class="blade"></div>
							<div class="blade"></div>
							<div class="blade"></div>
							<div class="blade"></div>
							<div class="blade"></div>
							<div class="blade"></div>
							<div class="blade"></div>
						</div>
						<div class='carregando'><span>Carregando... </span></div>
					</div>
				}
				{this.state.documents}
				<br /><br />
			</div >

		);
	}
}
