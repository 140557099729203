import { h, Component } from 'preact';
import Button from 'preact-material-components/Button';
import '../../../node_modules/preact-material-components/Button/style.css';
import Card from 'preact-material-components/Card';
import '../../..//node_modules/preact-material-components/Card/style.css';
import PreenchimentoRequisitos from './preenchimentoRequisitos'
import style from './style';
import BaseLegal from './baseLegal'

export default class Home extends Component {

	render() {
		return (
			<div class={`${style.home} page`}>
				<h1></h1>
				<Card class="card-intro">
					<div class={style.cardHeader}>
						<h2 class="mdc-typography--title">Bem vindo ao SCPP/ANPP</h2>
					</div>
					<div class={style.cardBody}>
						Para realização da simulação, é necessária a análise prévia dos requisitos de admissibilidade e aplicabilidade.
						<br />
						Caso essa análise já tenha sido realizada, clique em <b>"NÃO PARA TODOS"</b>.
					</div>

				</Card>

				<PreenchimentoRequisitos />

				<br />
				<br />
				<Card class='texto-codigo-penal'>
					<div class={style.cardHeader}>
						<h2 class="base-legal-titulo">Base Legal: Código de Processo Penal</h2>
					</div>
					<div class={style.cardBody}>
						<BaseLegal />
					</div>

				</Card>
			</div>
		);
	}
}
