/* eslint-disable indent */
import { h, Component } from 'preact';

import Card from 'preact-material-components/Card';
import 'preact-material-components/Card/style.css';
import Select from 'preact-material-components/Select';
import 'preact-material-components/List/style.css';
import 'preact-material-components/Menu/style.css';
import 'preact-material-components/Select/style.css';
import CurrencyInput from 'react-currency-input';
import style from './style';
import AnimateOnChange from 'preact-animate-on-change';
import TextField from 'preact-material-components/TextField';
import 'preact-material-components/TextField/style.css';


export default class CardSegundaFase extends Component {


    calculaPercentualTeto = () => {
        let teto = 359 * this.props.parentState.salarioMinimoFloat
        let valorPercentualTeto = teto * (this.props.parentState.percentualTetoFloat / 100)
        let valorFinal = valorPercentualTeto + this.props.parentState.salarioMinimoFloat
        this.props.atualizaState({
            valorPercentualTeto: valorPercentualTeto,
            valorFinal: valorFinal,
            teto: teto
        })
    }

    handleChangePercentualTeto = (event, maskedValue, floatValue) => {
        this.props.atualizaState({
            percentualTeto: maskedValue,
            percentualTetoFloat: floatValue
        })
        this.calculaPercentualTeto()
    }

    handleChangeCurrency = (event, maskedValue, floatValue) => {
        this.props.atualizaState({
            salarioMinimo: maskedValue,
            salarioMinimoFloat: floatValue,
        })
        this.calculaPercentualTeto()

    }




    render() {
        // console.log('selectedindex', this.props.parentState.indexFaixaRenda)
        let percentualMininimoMaximo
        if (this.props.parentState.maxPercentualTetoFloat == 0) {
            percentualMininimoMaximo = <span class="faixa-percentual-valores" > 0% (Apenas um salário mínimo) </span>
        } else {
            percentualMininimoMaximo = (

                <span class="faixa-percentual-valores">
                    <AnimateOnChange
                        baseClassName="pontuacaoTotal"
                        animationClassName="highlightText"
                        animate={this.props.parentState.minPercentualTetoFloatDiff != 0}
                    >
                        {this.props.parentState.minPercentualTetoFloat}%
                            </AnimateOnChange>
                            &nbsp;a&nbsp;
                    <AnimateOnChange
                        baseClassName="pontuacaoTotal"
                        animationClassName="highlightText"
                        animate={this.props.parentState.maxPercentualTetoFloatDiff != 0}
                    >

                        {this.props.parentState.maxPercentualTetoFloat}%
                            </AnimateOnChange>
                            &nbsp;do Teto da Multa
                </span>

            )
        }


        return (

            <Card>
                <h2 class="mdc-typography--title">Fase 2</h2>


                {/* faixa de renda */}
                <div class="mdc-typography--caption">Faixa de renda</div>
                <Select hintText="Escolha uma opção"
                    selectedIndex={this.props.parentState.indexFaixaRenda}
                    onChange={this.props.atualizaParentFaixaPercentualTeto}>

                    <Select.Item value={'rendaNivel0'}>PF baixa renda (até 2 salários-mínimos)</Select.Item>
                    <Select.Item value={'rendaNivel1'}>PF ou PJ com patrimônio ou receita anual de até R$ 360.000,00</Select.Item>
                    <Select.Item value={'rendaNivel2'}>PF ou PJ com patrimônio ou receita anual entre R$ 360.000,01 e R$ 3.600.000,00</Select.Item>
                    <Select.Item value={'rendaNivel3'}>PF ou PJ com patrimônio ou receita anual entre R$ 3.600.000,01 e R$ 12.000.000,00</Select.Item>
                    <Select.Item value={'rendaNivel4'}>PF ou PJ com patrimônio ou receita anual acima de R$ 12.000.000,00</Select.Item>

                </Select>
                <br />
                <div class="mdc-typography--caption"> Salário mínimo de referência: </div>

                {/* Salario minimo */}
                <CurrencyInput
                    className="mdc-select__native-control salario-minimo-referencia"
                    value={this.props.parentState.salarioMinimo}
                    onChangeEvent={this.handleChangeCurrency}
                    decimalSeparator=','
                    thousandSeparator='.'
                    prefix="R$ "
                />
                <br />
                <div >
                    <span class="mdc-typography--caption" >Faixa percentual sugerida sobre o teto da multa: </span>
                    <div class='percentual-min-max-div'>
                        {percentualMininimoMaximo}
                    </div>
                </div>
                <br />
                {/* Faixa Percentual aplicável ao crime */}
                <div class="faixa-percentual">
                    <br />
                    <div>
                        <span class="mdc-typography--caption">Defina o valor percentual para o caso: &nbsp;&nbsp;
                            {/* percentual teto NÃO É CURRENCY, MAS SERVE PARA USAR A VÍRGULA */}
                            <CurrencyInput
                                style={{ textAlign: 'center' }}
                                className="mdc-select__native-control"
                                value={this.props.parentState.percentualTeto}
                                onChangeEvent={this.handleChangePercentualTeto}
                                decimalSeparator=','
                                thousandSeparator='.'
                                precision="2"
                                suffix="%"
                            />
                        </span>

                    </div>
                </div >

                <br />
            </Card >

        )
    }
}
