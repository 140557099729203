/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable semi */
/* eslint-disable indent */
import Card from 'preact-material-components/Card';
import 'preact-material-components/Card/style.css';
import Select from 'preact-material-components/Select';
import 'preact-material-components/List/style.css';
import 'preact-material-components/Menu/style.css';
import 'preact-material-components/Select/style.css';
import { h, Component } from 'preact';
import AnimateOnChange from 'preact-animate-on-change';
import style from './style';

export default class CardPrimeiraFase extends Component {
    state = {
        pontuacaoTotalDiff: 0,
        pontuacaoGravidadeDiff: 0

    }
    somaPontuacaoFase1 = () => {
        const valor = parseInt(this.props.parentState.motivacaoCrime) + parseInt(this.props.parentState.consequenciaCrime) + parseInt(this.props.parentState.itinerarioCrime);
        // console.log('[VALOR]', valor, this.props.parentState.pontuacaoTotal);
        if (valor != this.props.parentState.pontuacaoTotal) {
            this.setState({ pontuacaoTotalDiff: 1 });
        }
        else {
            this.setState({ pontuacaoTotalDiff: 0 });
        }

        const gravidade = this.calculaGravidadeConduta(valor);

        if (gravidade != this.props.parentState.pontuacaoGravidade) {
            this.setState({ pontuacaoGravidadeDiff: 1 });
        }
        else {
            this.setState({ pontuacaoGravidadeDiff: 0 });
        }


        this.props.atualizaState({
            pontuacaoTotal: valor,
            pontuacaoGravidade: gravidade
        });
        this.props.atualizaParentFaixaPercentualTeto();
    }


    calculaGravidadeConduta = (pontuacao) => {
        // console.log('valor', pontuacao);
        switch (true) {
            case pontuacao > 81:
                return 'E';
            case pontuacao > 60:
                return 'D';
            case pontuacao > 40:
                return 'C';
            case pontuacao > 21:
                return 'B';
            case pontuacao <= 21:
                return 'A';
        }

    }

    render() {

        return (

            <Card>
                <h2 class=" mdc-typography--title">Fase 1</h2>

                <div class=" mdc-typography--caption">Motivação do Crime</div>
                <Select hintText="Escolha uma opção"
                    selectedIndex={this.props.parentState.indexMotivacaoCrime}
                    onChange={(e) => {
                        let index = e.target.selectedIndex
                        this.props.atualizaState({
                            motivacaoCrime: e.target.value,
                            motivacaoCrimeString: e.target[index].text,
                            motivacaoCrimeTemp: e.target.value,
                            indexMotivacaoCrime: e.target.selectedIndex
                        });
                        this.somaPontuacaoFase1();
                    }}
                >
                    <Select.Item value={20}>Crime culposo</Select.Item>
                    <Select.Item value={35}>Crime doloso</Select.Item>
                    <Select.Item value={55}>Crime preterdoloso</Select.Item>
                </Select>
                <div>
                    Pontos: {this.props.parentState.motivacaoCrime}
                </div>
                <br />
                <div class=" mdc-typography--caption">Consequências do Crime</div>
                <Select hintText="Escolha uma opção"
                    selectedIndex={this.props.parentState.indexConsequenciaCrime}
                    onChange={(e) => {
                        let index = e.target.selectedIndex
                        this.props.atualizaState({
                            consequenciaCrime: e.target.value,
                            consequenciaCrimeString: e.target[index].text,
                            indexConsequenciaCrime: e.target.selectedIndex
                        });
                        this.somaPontuacaoFase1();
                    }}
                >
                    <Select.Item value={5}>Mínimas</Select.Item>
                    <Select.Item value={11}>Moderadas</Select.Item>
                    <Select.Item value={35}>Significativas</Select.Item>
                </Select>
                <div>
                    Pontos: {this.props.parentState.consequenciaCrime}
                </div>
                <br />
                <div class=" mdc-typography--caption">Itinerário do Crime</div>
                <Select hintText="Escolha uma opção"
                    selectedIndex={this.props.parentState.indexItinerarioCrime}
                    onChange={(e) => {
                        let index = e.target.selectedIndex
                        this.props.atualizaState({
                            itinerarioCrime: e.target.value,
                            itinerarioCrimeString: e.target[index].text,
                            indexItinerarioCrime: e.target.selectedIndex
                        });
                        if (e.target.selectedIndex == 1) {

                            this.props.atualizaState({ motivacaoCrime: 0 });
                        }
                        else {
                            this.props.atualizaState({ motivacaoCrime: this.props.parentState.motivacaoCrimeTemp });
                        }
                        this.somaPontuacaoFase1();
                    }}
                >
                    <Select.Item value={15}>Preparação - Art. 5º, Lei nº 13.260/16</Select.Item>
                    <Select.Item value={0}>Tentativa</Select.Item>
                    <Select.Item value={15}>Consumação</Select.Item>
                </Select>
                <div>
                    Pontos: {this.props.parentState.itinerarioCrime}
                </div>
                <div class='resultado'>
                    <div class="pontuacao-resultado">
                        <span>Pontuação:&nbsp;</span>
                        <AnimateOnChange
                            baseClassName="pontuacaoTotal"
                            animationClassName="highlightText"
                            animate={this.state.pontuacaoTotalDiff != 0}
                        >
                            {this.props.parentState.pontuacaoTotal}
                        </AnimateOnChange>
                    </div>
                    <div class="pontuacao-resultado">
                        Gravidade:&nbsp;
    					<AnimateOnChange
                            baseClassName="pontuacaoTotal"
                            animationClassName="highlightText"
                            animate={this.state.pontuacaoGravidadeDiff != 0}
                        >
                            {this.props.parentState.pontuacaoGravidade}
                        </AnimateOnChange>
                    </div>

                </div>


            </Card >

        );
    }
}
